import ManageSender from "./Layouts/sender/ManageSender";
// import CreateTemplate from "./Layouts/smsmodule/CreateTemplete";
import ManageEntity from "./Layouts/Entity/ManageEntity";
import Shop from "./components/Icons/Shop";
import ManageUsers from "./Layouts/Users/ManageUsers";
import ManageParameter from "./Layouts/Parameter/ManageParameter";
import ManageCampaign from "./Layouts/Campigin/ManageCampaign";
import SignInSide from "./Layouts/auth/SignIn";
import Dashboard from "./Layouts/Dashboard/Dashboard";
import ManageTemplate from "./Layouts/smsTemplete/ManageTemplate";
import CampaignIcon from '@mui/icons-material/Campaign';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import OutboxIcon from '@mui/icons-material/Outbox';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FenceIcon from '@mui/icons-material/Fence';
import ManageClientApiJson from "./Layouts/ClientApiJSON/ManageClientApiJson";
import ComponentJSON from "./Layouts/ComponentJSON/ComponentJSON";
import ManageSendSms from "./Layouts/sendSMS/ManageSendSms";
import ManageMenumap from "./Layouts/Menumapping/ManageMenumap";
import DynamicApiCall from "./utils/function";

//Add Component file on bases of key
const pages = {
  MNU_DASHBOARD: {component:<Dashboard/>,icon:<Shop size="12px" />},
  MNU_DISPLAY_ENTITES: {component:<ManageEntity />,icon:<FingerprintIcon size="12px" />},
  MNU_DISPLAY_CAMPAIGN: {component:<ManageCampaign />,icon:<CampaignIcon size="12px" />},
  MNU_DISPLAY_SENDER: {component:<ManageSender />,icon:<OutboxIcon size="12px" />},
  MNU_DISPLAY_TEMPLATE: {component:<ManageTemplate />,icon:<Shop size="12px" />},
  MNU_DISPLAY_SMS: {component:<ManageSendSms/>,icon:<Shop size="12px" />},
  MNU_DISPLAY_USER: {component:<ManageUsers />,icon:<ManageAccountsIcon size="12px" />},
  MNU_DISPLAY_MENU_MAP: {component:<ManageMenumap />,icon:<ManageAccountsIcon size="12px" />},
  MNU_DISPLAY_PARAMETER: {component:<ManageParameter />,icon:<FenceIcon size="12px" />},
  MNU_DISPLAY_CLIENTAPI_JSON: {component:<ManageClientApiJson />,icon:<FenceIcon size="12px" />},
  MNU_DISPLAY_COMPONENT_JSON: {component:<ComponentJSON />,icon:<FenceIcon size="12px" />},
};

// Fetch routes from API
async function fetchRoutesFromAPI(userid,usergroup, token) {
  try {
    const Info = await DynamicApiCall(`multimedia/getmenumapagent/${userid}/${usergroup}`, "get", token);
    return Info.data;
  } catch (error) {
    console.error('Error fetching routes from API:', error);
    return [];
  }
}

// Function to replace component and icon values
async function routes(userInfo) {
  console.log("userInfo3",userInfo)
  const { userid,usergroup,token } = userInfo;
  const apiRoutes = await fetchRoutesFromAPI(userid,usergroup,token);
  const updatedRoutes = apiRoutes.map(route => {
    const { component: componentName, icon: iconString, ...rest } = route;
    const page = pages[componentName]; // Get corresponding component and icon from pages object
    const icon = page ? page.icon : null;
    const component = page ? page.component : null;
    return { ...rest, icon, component };
  });
  console.log("updatedRoutes",updatedRoutes)

  return updatedRoutes;
}

export default routes;