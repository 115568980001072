import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "../../components/SoftBox";
import DynamicForm from "../../helpers/formikForm";
import * as Yup from "yup";
import SoftButton from "../../components/SoftButton";
import DynamicApiCall from "../../utils/function";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

export default function AddProtocol({ tempData }) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { userid, token } = userInfo;
  const [formValue, setFromValue] = useState({});
  const [finalApiBody, setFinalApiBody] = useState({});
  const [studies, setStudies] = useState([]);

  const JsonFields = {
    initialValues: { phone: "" },
    data: [
      {
        name: "stydyname",
        placeholder: "Enter Study Name",
        validation: Yup.string().required("Ptudy Name required"),
        type: "text",
        fullWidth: true,
      },
    ],
    buttons: {
      className: "space-around",
      submitButton: {
        style: {},
        label: "Check Protocol",
      },
      resetButton: {
        style: {},
        label: "Clear",
      },
    },
  };

  useEffect(() => {
    async function replaceData(tempData) {
      let variables = tempData?.tempvariables?.split("|");
      let dynamicFields = await variables
        .map((variable, index) => {
          if (index === 0) {
            return null;
          }
          return {
            name: variable,
            placeholder: `Enter ${variable}`,
            validation: Yup.string().required(`${variable} required`),
            type: "text",
            fullWidth: true,
          };
        })
        .filter((field) => field !== null);

      setFromValue((prevValue) => ({
        ...JsonFields,
        data: [...JsonFields.data, ...dynamicFields],
      }));
    }

    if (tempData) {
      replaceData(tempData);
    }
  }, [tempData]);

  useEffect(() => {
    async function fetchData(tempData) {
      await getStudies(tempData);
    }
    if (tempData) {
      fetchData(tempData);
    }
  }, [tempData]);

  async function formsubmit(values) {
    try {
      let regx = new RegExp(tempData.tempvariables, "gi");
      let content = tempData.tempbody.replace(regx, function (match) {
        return values[match];
      });
      let variablevalue = {};
      tempData.tempvariables.split("|").forEach((variable) => {
        if (values[variable]) {
          variablevalue[variable] = values[variable];
        }
      });
      console.log("variablevalue", variablevalue);
      let templete = {
        textContent: content,
        templateid: tempData.templateid,
        studyname: values.stydyname,
        variablevalue: JSON.stringify(variablevalue),
        createdby: tempData.userid,
      };
      console.log("templete", templete);

      setFinalApiBody(templete);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  async function sendSms() {
    try {
      const apiUrl = "/sms/addprotocol";
      const method = "post";

      const apiResponse = await DynamicApiCall(apiUrl, method, token, {
        ...finalApiBody,
        ...tempData,
      });
      if (apiResponse.message === "SUCCESS") {
        await getStudies(tempData);
      }
    } catch (error) {}
  }

  async function getStudies(tempData) {
    try {
      const apiUrl = `/sms/getprotocol/${tempData.templateid}/ALL`;
      const method = "get";
      const apiResponse = await DynamicApiCall(apiUrl, method, token, {});
      setStudies(apiResponse.data);
    } catch (error) {}
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <SoftBox mt={1}>
            <SoftBox mb={3}>
              <Card style={{ padding: "10px" }}>
                <Grid container justifyContent="space-between" px={2} pt={1}>
                  <Grid item>Add Variable Data</Grid>
                </Grid>
                <hr
                  style={{
                    backgroundColor: "#e8e5e5",
                    margin: "0 auto",
                    width: "100%",
                  }}
                />
                {Object.keys(formValue).length > 0 && (
                  <DynamicForm
                    submitfunction={formsubmit}
                    initialValues={JsonFields.initialValues}
                    fields={formValue}
                  />
                )}
              </Card>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <SoftBox style={{ paddingTop: "0", maeginTop: "0" }}>
            <SoftBox mb={3}>
              <Card style={{ padding: "10px" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  px={2}
                >
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>Final SMS Body</div>
                    </div>
                  </Grid>
                  <Grid item mb={2}>
                    <SoftButton
                      variant="contained"
                      size="small"
                      color="success"
                      type="submit"
                      onClick={sendSms}
                    >
                      Save
                    </SoftButton>
                  </Grid>
                </Grid>
                <hr
                  style={{
                    backgroundColor: "#e8e5e5",
                    margin: "0 auto",
                    width: "100%",
                  }}
                />
                <pre
                  style={{
                    overflowX: "auto",
                    maxHeight: "300px",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#ccc #f0f0f0",
                    fontSize: "small",
                  }}
                >
                  {/* {JSON.stringify(finalApiBody?.textContent, null, 2)} */}
                  {finalApiBody?.textContent}
                </pre>
              </Card>
            </SoftBox>
            {studies.length > 0 ? (
              <SoftBox mb={3}>
                <Card style={{ padding: "10px" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    pt={1}
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>Studies Added</div>
                      </div>
                    </Grid>
                    <hr
                      style={{
                        backgroundColor: "#e8e5e5",
                        margin: "0 auto",
                        width: "100%",
                      }}
                    />
                    <Grid item mb={2} mt={2} xs={12} md={12} lg={12}>
                      {studies.map((value, key) => {
                        console.log("value", value);
                        return (
                          <Tooltip
                            // title={JSON.stringify(value?.jsoncolumn)}
                            title={
                              <div>
                                {Object.entries(value?.jsoncolumn).map(
                                  ([key, value]) => (
                                    <p key={key}>
                                      {key}: {value}
                                    </p>
                                  )
                                )}
                              </div>
                            }
                            color="inherit"
                            placement="bottom-start"
                          >
                            <SoftButton
                              key={key}
                              variant="outlined"
                              size="small"
                              color="primary"
                              style={{ marginLeft: "5px", marginTop: "5px" }}
                            >
                              {value?.project_study}
                            </SoftButton>
                          </Tooltip>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Card>
              </SoftBox>
            ) : (
              ""
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </>
  );
}
